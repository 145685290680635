import { Configuration, PublicClientApplication } from "@azure/msal-browser";

const config: Configuration = {
  auth: {
    authority:
      "https://login.microsoftonline.com/86cbe1bb-213f-4271-b174-bd59d03c87a3",
    clientId: "61b5eb1b-4c70-493d-9d46-6593f40d7b0c",
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const authProvider = new PublicClientApplication(config);
